





import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv2/atoms/Title.vue'
import CorrectGuid from '@/components/modules/drills/atoms/CorrectGuide.vue'
import Correct from '@/components/modules/drillsv2/organisms/Correct.vue'
import Transition from '@/components/modules/drillsv2/organisms/Transition.vue'

import { PAGE_TYPE } from '@/store/modules/DrillsV2'

import DrillsConfig from '@/mixins/drillsv2/Config'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import DrillWebApi from '@/mixins/drillsv2/WebApi'

@Component({
  components: {
    Title,
    CorrectGuid,
    Transition,
    Correct,
  },
})
export default class DrillScoring extends Mixins(DrillWebApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private drillModules = DrillsConfig.drillModules

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private get page(): number {
    return Number(this.$route.query.page)
  }

  protected async mounted() {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- DrillScoring V2 mounted')
    // ページ未指定の場合は問題画面へ戻す
    if (!this.page) {
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlDrill,
      })
      return
    }

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV2/setProcessed', false)

    // ページタイプ設定
    this.$store.commit('drillsV2/setPageType', PAGE_TYPE.SCORING_UNIT)

    // 正誤入力情報を取得
    const resultProblem = await this.getResultProblem(this.resultDrillId, this.page)
    this.$store.commit('drills/setEndCondition', resultProblem.endConditions || {})
    this.$store.commit('drillsV2/setCurrentPage', Number(resultProblem.pageNum))
    if (resultProblem?.endConditions?.left === 0 && this.isV3) {
      await this.completeDrillApi(this.resultDrillId)
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }

    // 授業モードと授業種別を保持
    this.$store.commit('drillsV2/setClassModeCode', resultProblem.classModeCode)
    this.$store.commit('drillsV2/setClassCategoryCode', resultProblem.classCategoryCode)

    // 該当ページが採点済みの場合は問題画面へ(問題ごとの結果画面がないため)
    //    問題ごとの結果画面が存在するモードが追加された場合、DrillTransitionに処理を追加し、ClassModeなどを元に適切な遷移先を選ぶようにする
    if (resultProblem.isCorrect) {
      this.$router.push({
        path: this.urlDrill,
      })
      return
    }
    await this.$store.dispatch('drillsV2/setProblemCorrects', [resultProblem.problems])

    // 表示用の情報を取得し、ストアに保持
    const sProblemIds = resultProblem.problems.map((problem) => {
      return problem.sProblemId
    })
    const problems = await this.getProblems([sProblemIds])
    await this.$store.dispatch('drillsV2/setProblems', problems)

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV2/setProcessed', true)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }
}
